export default defineNuxtRouteMiddleware(_ => {
	const { $i18nGlobal } = useNuxtApp();
	const { t } = $i18nGlobal;
	const { platformName } = storeToRefs(usePlatformStore());

	useSeoMeta({
		title: () => t("pages.home.default_welcome", { mktplc: platformName.value }),
		description: "",

		// keywords,
		ogTitle: () => t("pages.home.default_welcome", { mktplc: platformName.value }),
		ogDescription: "",
		ogType: "website",
		ogImageUrl: "",
		ogImageSecureUrl: "",
		twitterTitle: t("pages.home.default_welcome", { mktplc: platformName.value }),
		twitterDescription: "",
		twitterType: "website",
		twitterImage: "",
	});
});
